<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        Acompanhamento Custo
        <v-spacer />
        <v-row
          class="d-flex justify-end"
        >
          <v-col
            cols="12"
            md="4"
            sm="6"
          >
            <comboLoja
              :dense="true"
              @onSelectLoja="onSelectLoja"
            />
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-card
      v-if="existeDados"
      class="pa-2"
    >
      <v-btn
        outlined
        color="success"
        @click="onBaixarExcel"
      >
        <v-icon
          right
          dark
          class="pr-5"
          color="green darken-2"
        >
          mdi-microsoft-excel
        </v-icon>
        Excel
      </v-btn>
    </v-card>
    <v-card>
      <v-data-table
        dense
        :headers="headers"
        :items="listaPedido"
        :search="search"
      >
        <template v-slot:item.cdItem="{ item }">
          <span v-if="item.cdItem !== 0">{{ item.cdItem }}{{ item.cdSubItem }}</span>
          <strong v-else>TOTAIS</strong>
        </template>
        <template v-slot:item.precoMedioEntrada="{ item }">
          <span v-if="item.cdItem !== 0">{{ formatPrice(item.precoMedioEntrada) }}</span>
        </template>
        <template v-slot:item.precoMedioSaida="{ item }">
          <span v-if="item.cdItem !== 0">{{ formatPrice(item.precoMedioSaida) }}</span>
        </template>
        <template v-slot:item.totQtSaida="{ item }">
          <span v-if="item.cdItem !== 0">{{ formatPrice(item.totQtSaida) }}</span>
        </template>
        <!-- <template v-slot:item.totQtEntrada="{ item }">
          <span v-if="item.cdItem !== 0">{{ formatPrice(item.totQtEntrada) }}</span>
        </template> -->
        <template v-slot:item.totalPrecoMedio="{ item }">
          <span v-if="item.cdItem !== 0">{{ formatPrice(item.totalPrecoMedio) }}</span>
          <strong v-else>{{ formatPrice(item.totalPrecoMedio) }}</strong>
        </template>
        <template v-slot:item.totalPrecoCustoMedio="{ item }">
          <span v-if="item.cdItem !== 0">{{ formatPrice(item.totalPrecoCustoMedio) }}</span>
          <strong v-else>{{ formatPrice(item.totalPrecoCustoMedio) }}</strong>
        </template>
        <template v-slot:item.totalLucroBruto="{ item }">
          <span v-if="item.cdItem !== 0">{{ formatPrice(item.totalLucroBruto) }}</span>
          <strong v-else>{{ formatPrice(item.totalLucroBruto) }}</strong>
        </template>
        <template v-slot:item.totalDespesas="{ item }">
          <span v-if="item.cdItem !== 0">{{ formatPrice(item.totalDespesas) }}</span>
          <strong v-else>{{ formatPrice(item.totalDespesas) }}</strong>
        </template>
        <template v-slot:item.totalLiquido="{ item }">
          <span v-if="item.cdItem !== 0">{{ formatPrice(item.totalLiquido) }}</span>
          <strong v-else>{{ formatPrice(item.totalLiquido) }}</strong>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>

  import estoqueRep from '../../components/services/estoqueRepository'
  import comboLoja from '../componentes/componente-loja.vue'
  export default {
    components: {
      comboLoja,
    },
    data () {
      return {
        headers: [
          {
            text: 'Item',
            align: 'start',
            value: 'cdItem',
            width: 120,
          },
          {
            text: 'Descrição',
            align: 'start',
            value: 'nmItem',
            width: 300,
          },
          {
            text: 'Qtd. Saída',
            align: 'right',
            value: 'totQtSaida',
            width: 150,
          },
          {
            text: 'Preço Méd. Saí.',
            align: 'right',
            value: 'precoMedioSaida',
            width: 150,
          },
          {
            text: 'Tot. Vendido',
            align: 'right',
            value: 'totalPrecoMedio',
            width: 150,
          },
          {
            text: 'Preço Méd. Entr.',
            align: 'right',
            value: 'precoMedioEntrada',
            width: 150,
          },
          // {
          //   text: 'Tot. Qtd. Ent.',
          //   align: 'right',
          //   value: 'totQtEntrada',
          //   width: 150,
          // },
          {
            text: 'Tot. Venda/Preço Custo',
            align: 'right',
            value: 'totalPrecoCustoMedio',
            width: 180,
          },
          {
            text: 'Tot. Lucro Bruto',
            align: 'right',
            value: 'totalLucroBruto',
            width: 180,
          },
          {
            text: 'Tot. Despesas',
            align: 'right',
            value: 'totalDespesas',
            width: 180,
          },
          {
            text: 'Tot. Liquido',
            align: 'right',
            value: 'totalLiquido',
            width: 180,
          },
        ],
        listaPedido: [],
        search: '',
        lojaSelecionada: 0,
        existeDados: false,
        urlbase: '',
      }
    },
    methods: {
      async onCarregarEstoque () {
        this.$store.commit('setSplashScreen', true)
        await estoqueRep.get(this.lojaSelecionada).then(response => {
          if (response.data.result.length > 0) {
            this.listaPedido = response.data.result
            this.existeDados = true
          } else {
            this.existeDados = false
          }

          this.$store.commit('setSplashScreen', false)
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
          })
        })
      },
      onSelectLoja (loja) {
        this.lojaSelecionada = loja.cdLoja
        this.onCarregarEstoque()
      },
      async onBaixarExcel () {
        this.$store.commit('setSplashScreen', true)
        await estoqueRep.getReport(this.lojaSelecionada, 'E').then(response => {
          this.urlbase = this.$store.getters.empresa.url.includes('locahost') ? this.$store.getters.empresa.url.replace('/Api/', '').replace('/api/', '') + '/temp/' + response.data : this.$store.getters.empresa.url.replace('/sfcloud/Api/', '').replace('/sfcloud/api/', '') + '/temp/' + response.data
          this.$store.commit('setSplashScreen', false)
          this.downloadFile()
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
          })
        })
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      downloadFile () {
        // console.log(this.urlbase)
        window.open(this.urlbase, '_blank')
      },
    },
  }
</script>
