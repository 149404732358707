import Repository from './repository'

const resource = '/estoque'

export default {

  get (loja) {
    return Repository.get(`${resource}?loja=${loja}`)
  },
  getReport (loja, tipo) {
    return Repository.get(`${resource}/report?loja=${loja}&tipo=${tipo}`)
  },
}
